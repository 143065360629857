<script setup>
  import { ref } from 'vue'
  import CatalogPublicationFilters from '../pages/CatalogPublicationFilters.vue';
  import PublicationTable from './catalog/PublicationTable.vue';

  const title = ref('Catalog Publication');
  const lang = ref();
  document.title = title.value;
  lang.value = localStorage.getItem("lang");
</script>

<template>
  <div class="container">
    <h1 class="title">{{ title }}</h1>
    <div id="teleport-alert"></div>
    <div class="with_bst">
      <CatalogPublicationFilters :lang="lang" />
      <hr class="hr" />
    </div>
    <PublicationTable ref="publicationTable" />
  </div>
</template>

<style scoped lang="scss">
  :deep() {
    @import '../../../node_modules/bootstrap/dist/css/bootstrap';
    @import '../../../node_modules/bootstrap-vue-next/dist/bootstrap-vue-next';
  }

  :deep(.custom-offcanvas) {
    min-height: 540px !important;
  }
  .title {
    color: black;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 18px;
  }
</style>
